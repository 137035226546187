.generic-css{
  display: flex;
  justify-content: center;
}
.white-colored{
color: #fff;
}
.rating span { 
  margin:0.1rem;
}
.my-button {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 0.5rem;
}
.round {
  border-radius: 0.5rem;
}

main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
  color:'white'
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 200px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
#main-menu:last-child {
  background-color: red;
}
.carousel a {
  margin: 0 auto;
}
#category-dropdown{
  width: 12.5rem;
  border-radius: 0.5rem;
}

.left-handside {
  border-radius: 0.5rem 0 0 0.5rem;
}

.right-handside {
  border-radius: 0 0.5rem 0.5rem 0;
}
.mr-sm-2 > :last-child{
 
  width: 100%;
  
}

.custom-dropdown-button .dropdown-item {
  text-align: center;
  width: 100%;
  border-radius: 0.5rem;
}

/*running text header*/
.knoOqT{
  display: flex;
  justify-content: space-around;
}
.knoOqT:hover{
  display: flex;
  justify-content: space-around;
}
/*running text header*/
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
@media (max-width: 991px) {
  #homescreen-title{
    color: white;
    text-align: center;
  }
  .container{
    padding-left:1rem;
    padding-right: 1rem;
  }
  .navbar-collapse{
    margin-top: 1rem;
  }
  .navbar-nav{
    transform: scale(0.8);
  }
  .ms-auto{
    flex-direction: column;
  }
  #category-dropdown{
    width: 100%;
  }
}

.article-swiper-slide {
  padding: 0 10px;
}

.slick-track{
  display: flex !important;
  justify-content: center !important;
}
.card-img-top {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.fade-in {
  opacity: 0;
  transition: opacity 2s;
}

.fade-in.visible {
  opacity: 1;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}


.scrolling-text {
  display: flex;
  justify-content: space-around;
  height: 30px; /* adjust according to your needs */
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #343a40;
  color: #fff;
}

.text-container {
  display: flex;
  justify-content: space-between; /* This will distribute the remaining space evenly between your messages */
  position: absolute;
  width: 100%; /* Now the width is as wide as the parent element */
  height: 100%;
  line-height: 30px;
  /* Starting position */
  transform: translateX(100%);
  /* Apply animation to this element */
  animation: scroll-left 10s linear infinite;
}

.text {
  margin: 0;
  text-align: center;
  white-space: pre;
  padding-right: 2rem;
}

.scrolling-text:hover .text-container {
  animation-play-state: paused;
}

/* Move it (define the animation) */
@keyframes scroll-left {
  0%   {
    transform: translateX(100%);  
  }
  100% {
    transform: translateX(-100%); 
  }
}

#enableOtp{
  border-radius: 0.5rem;
}
.minHeight{
  min-height: 26.9em;
}

main[dir="rtl"] #article-content {
  display: flex;
  justify-content: flex-end;
}

.dropdown-menu {
  border-radius: 0.5em;
}

html[lang="he"] .dropdown-menu > a{
 text-align: center; 
}

html[lang="he"] #search-box-input {
  direction: rtl; 
}
html[lang="he"] .form-check{

    display: flex;
    flex-direction: row;
    column-gap: 3rem;
}
.view-articles-section {
  gap: 3rem;
  margin-bottom: 3rem;
}

html[lang='he'] .view-articles-section {
  margin-right: -2.5rem;
}

html:not([lang='he']) .view-articles-section {
  margin-right: 2.5rem;
}
